/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


@import "node_modules/bootstrap/scss/bootstrap";

.action-sheet-icon.sc-ion-action-sheet-md {
    padding-bottom: 4px;
    margin-bottom: 0;
    font-size: 24px;
    background: cornflowerblue;
    padding: 8px;
    border-radius: 6px;
    color: white;
}


.image-popup-modal-css {
    --height: 100%;
    --width: 100%;
    --margin: 40px;
}

.image-popup-modal-css .modal-wrapper {
  display: grid;
  grid-template-columns: auto;
  grid-auto-rows: minmax(min-content, max-content);
  height: 100%;
  border-radius: 10px;
  margin: 20px;
  position: absolute;
  top:0
}

.portfolio-help-modal-css{
    --height: 100%;
    --width: 100%;
    --margin: 40px;
}

.my-custom-class{

}
.modal-fullscreen{
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
  }

  .swiper-pagination {
    position: relative;
    padding-top: 10px;
}